import { ALL_CONFIG, CONFIG_BY_ID, CONFIG_ID } from "./action";

const initialState = {
  allConfig: [],
  ConfigById: [],
  configPaymentId: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CONFIG:
      return {
        ...state,
        allConfig: action.payload,
      };
    case CONFIG_BY_ID:
      return {
        ...state,
        ConfigById: action.payload,
      };
      case CONFIG_ID:
      return {
        ...state,
        configPaymentId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
