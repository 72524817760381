import { API } from "./api";

export const paymentService = {
  GetAllPayments(page, size, email) {
    return API.get("payment/GetPayments", {
      params: {
        page,
        size,
        email,
      },
    });
  },
  GetPaymentByOwnerId(ownerId, page, size) {
    return API.get(`payment/GetPaymentByOwnerId/${ownerId}`, {
      params: {
        page,
        size,
      },
    });
  },
  GetPaymentHistory(
    page,
    size,
    email,
    paymentType,
    status,
    payDate,
    accountId
  ) {
    return API.get("payment/GetPaymentHistory", {
      params: {
        page,
        size,
        payDate,
        email,
        paymentType,
        status,
        accountId,
      },
    });
  },
  GetPaymentReport(page, size, email, createDate, status, accountId) {
    return API.get("payment/GetPaymentReport", {
      params: {
        page,
        size,
        createDate,
        email,
        status,
        accountId,
      },
    });
  },
  GetPaymentReportById(id) {
    return API.get(`payment/GetPaymentReportById/${id}`);
  },
  ConfirmwithdrawPayment(id, formData) {
    return API.post(`payment/ConfirmwithdrawPayment/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
