import { toast } from "react-toastify";
import { chartService } from "../../services/chartService";

export const GET_NUMBER_ACCOUNT_BY_YEAR = "GET_NUMBER_ACCOUNT_BY_YEAR";
export const GET_NUMBER_SALON_BY_YEAR = "GET_NUMBER_SALON_BY_YEAR";
export const GET_APPOINTMENT_SUCCESS_BY_YEAR =
  "GET_APPOINTMENT_SUCCESS_BY_YEAR";
export const GET_APPOINTMENT_FAILED_BY_YEAR = "GET_APPOINTMENT_FAILED_BY_YEAR";
export const GET_APPOINTMENT_CANCELED_BY_YEAR =
  "GET_APPOINTMENT_CANCELED_BY_YEAR";
export const GET_APPOINTMENT_RATIO_BY_STATUS =
  "GET_APPOINTMENT_RATIO_BY_STATUS";
export const GET_APPOINTMENT_RATIO_BY_YEAR = "GET_APPOINTMENT_RATIO_BY_YEAR";
export const GET_REVENUE_BY_YEAR = "GET_REVENUE_BY_YEAR";
export const GET_COMMISSION_BY_YEAR = "GET_COMMISSION_BY_YEAR";
export const GET_CUSTOMER_APPROVED = "GET_CUSTOMER_APPROVED";
export const GET_SALON_APPROVED = "GET_SALON_APPROVED";
export const GetNumberofAccountinYear = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetNumberofAccountinYear(year);
    // console.log("GetVoucherBySalonId response:", response.data);
    dispatch({ type: GET_NUMBER_ACCOUNT_BY_YEAR, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetNumberofAccountinYear", errorMessage);
    toast.error("GetNumberofAccountinYear: ", errorMessage);
  }
};

export const GetNumberofSalonOwnerinYear = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetNumberofSalonOwnerinYear(year);
    // console.log("GetVoucherBySalonId response:", response.data);
    dispatch({ type: GET_NUMBER_SALON_BY_YEAR, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetNumberofSalonOwnerinYear", errorMessage);
    toast.error("GetNumberofSalonOwnerinYear: ", errorMessage);
  }
};

export const GetAppointmentbyStatusByAdmin = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetAppointmentbyStatusByAdmin(
      "SUCCESSED",
      year
    );
    const response1 = await chartService.GetAppointmentbyStatusByAdmin(
      "FAILED",
      year
    );
    const response2 = await chartService.GetAppointmentbyStatusByAdmin(
      "CANCEL_BY_CUSTOMER",
      year
    );
    // console.log("GetVoucherBySalonId response:", response.data);
    dispatch({ type: GET_APPOINTMENT_SUCCESS_BY_YEAR, payload: response.data });
    dispatch({ type: GET_APPOINTMENT_FAILED_BY_YEAR, payload: response1.data });
    dispatch({
      type: GET_APPOINTMENT_CANCELED_BY_YEAR,
      payload: response2.data,
    });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetAppointmentbyStatusByAdmin", errorMessage);
    toast.error("GetAppointmentbyStatusByAdmin: ", errorMessage);
  }
};

export const GetAppointmentRatioByStatus = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetAppointmentRatioByStatus(year);
    // console.log("GetVoucherBySalonId response:", response.data);
    dispatch({ type: GET_APPOINTMENT_RATIO_BY_STATUS, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetAppointmentRatioByStatus", errorMessage);
    toast.error("GetAppointmentRatioByStatus: ", errorMessage);
  }
};

export const GetAppointmentRatioInYear = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetAppointmentRatioInYear(year);
    dispatch({ type: GET_APPOINTMENT_RATIO_BY_YEAR, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetAppointmentRatioInYear", errorMessage);
    toast.error("GetAppointmentRatioInYear: ", errorMessage);
  }
};

export const GetRevenueByAdmin = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetRevenueByAdmin(year);
    dispatch({ type: GET_REVENUE_BY_YEAR, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetRevenueByAdmin", errorMessage);
    toast.error("GetRevenueByAdmin: ", errorMessage);
  }
};

export const GetCommissionByAdmin = (year) => async (dispatch) => {
  try {
    const response = await chartService.GetCommissionByAdmin(year);
    dispatch({ type: GET_COMMISSION_BY_YEAR, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetCommissionByAdmin", errorMessage);
    toast.error("GetCommissionByAdmin: ", errorMessage);
  }
};

export const GetNumberofCustomerApproved = () => async (dispatch) => {
  try {
    const response = await chartService.GetNumberofCustomerApproved();
    dispatch({ type: GET_CUSTOMER_APPROVED, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetNumberofCustomerApproved", errorMessage);
    toast.error("GetNumberofCustomerApproved: ", errorMessage);
  }
};

export const GetNumberofSalonOwnerApproved = () => async (dispatch) => {
  try {
    const response = await chartService.GetNumberofSalonOwnerApproved();
    dispatch({ type: GET_SALON_APPROVED, payload: response.data });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log("error GetNumberofSalonOwnerApproved", errorMessage);
    toast.error("GetNumberofSalonOwnerApproved: ", errorMessage);
  }
};
