import { toast } from "react-toastify";
import { appointmentService } from "src/services/appointmentService";
export const ACT_ALL_APPOINTMENT = "ACT_ALL_APPOINTMENT";

export function actGetAllAppointment(list) {
  return {
    type: ACT_ALL_APPOINTMENT,
    payload: list,
  };
}

export function GetAppointmentByStatus(status, page, size) {
  return async (dispatch) => {
    try {
      const response = await appointmentService.GetAppointmentByStatus(
        status,
        page,
        size
      );
      dispatch(actGetAllAppointment(response.data));
    } catch (error) {
      console.error("Failed to GetAppointmentByStatus:", error);
    }
  };
}
