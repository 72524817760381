import { toast } from "react-toastify";
import { paymentService } from "src/services/paymentService";
export const ACT_ALL_PAYMENT = "ACT_ALL_PAYMENT";
export const ACT_PAYMENT_BY_SALON_ID = "ACT_PAYMENT_BY_SALON_ID";
export const ACT_ALL_PAYMENTP_HISTORY = "ACT_ALL_PAYMENTP_HISTORY";
export const ACT_ALL_PAYMENTP_REPORT = "ACT_ALL_PAYMENTP_REPORT";
export const ACT_ALL_PAYMENTP_REPORT_ID = "ACT_ALL_PAYMENTP_REPORT_ID";

export function actGetAllPayments(list) {
  return {
    type: ACT_ALL_PAYMENT,
    payload: list,
  };
}
export function actGetPaymentBySalonId(list) {
  return {
    type: ACT_PAYMENT_BY_SALON_ID,
    payload: list,
  };
}
export function actGetAllPaymentsHistory(list) {
  return {
    type: ACT_ALL_PAYMENTP_HISTORY,
    payload: list,
  };
}
export function actGetAllPaymentsReport(list) {
  return {
    type: ACT_ALL_PAYMENTP_REPORT,
    payload: list,
  };
}
export function actGetAllPaymentsReportById(list) {
  return {
    type: ACT_ALL_PAYMENTP_REPORT_ID,
    payload: list,
  };
}

export function getAllPayments(page, size, email) {
  return async (dispatch) => {
    try {
      const response = await paymentService.GetAllPayments(page, size, email);
      dispatch(actGetAllPayments(response.data));
    } catch (error) {
      console.error("Failed to GetPaymentBySalonId:", error);
    }
  };
}
export function GetPaymentBySalonId(ownerId, page, size) {
  return async (dispatch) => {
    try {
      const response = await paymentService.GetPaymentByOwnerId(
        ownerId,
        page,
        size
      );
      dispatch(actGetPaymentBySalonId(response.data));
    } catch (error) {
      console.error("Failed to GetPaymentBySalonId:", error);
    }
  };
}
export function GetPaymentHistory(
  page,
  size,
  email,
  paymentType,
  status,
  payDate,
  accountId
) {
  return async (dispatch) => {
    try {
      const response = await paymentService.GetPaymentHistory(
        page,
        size,
        email,
        paymentType,
        status,
        payDate,
        accountId
      );
      dispatch(actGetAllPaymentsHistory(response.data));
    } catch (error) {
      console.error("Failed to GetPaymentHistory:", error);
    }
  };
}
export function GetPaymentReport(
  page,
  size,
  status,
  email,
  createDate,
  accountId
) {
  return async (dispatch) => {
    try {
      const response = await paymentService.GetPaymentReport(
        page,
        size,
        email,
        createDate,
        status,
        accountId
      );
      dispatch(actGetAllPaymentsReport(response.data));
    } catch (error) {
      console.error("Failed to GetPaymentReport:", error);
    }
  };
}
export function GetPaymentReportById(id) {
  return async (dispatch) => {
    try {
      const response = await paymentService.GetPaymentReportById(id);
      dispatch(actGetAllPaymentsReportById(response.data));
    } catch (error) {
      console.error("Failed to actGetAllPaymentsReportById:", error);
    }
  };
}
