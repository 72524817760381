import React, { Component, Suspense, useEffect } from "react";
import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./scss/style.scss";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { UserServices } from "./services/userServices";
import { actUserLogin } from "./store/user/action";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// Tạo một component mới để xử lý routing
function RouterContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");

  console.log("admin");
  
  const isTokenValid = (token) => {
    if (!token || token.split('.').length !== 3) {
      console.error("Invalid token format:", token);
      return false;
    }
    
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decodedToken.exp > currentTime; // Check if the token is not expired
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  const fetchUserInfo = async (token) => {
    try {
      const res = await UserServices.fetchMe(token);
      if (res.data && res.data.adminResponse) {
        const currentUser = res.data.adminResponse;
        const role = res.data.roleName;
        return { currentUser, role };
      } else {
        throw new Error("Failed to fetch user info");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      return null;
    }
  };

  const refreshTokenFu = async (refreshToken) => {
    try {
      const response = await UserServices.refreshToken(refreshToken);
      if (response.data && response.data.accessToken) {
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;
        localStorage.setItem("ACCESS_TOKEN", newAccessToken);
        localStorage.setItem("REFRESH_TOKEN", newRefreshToken);
        return { newAccessToken, newRefreshToken };
      } else {
        throw new Error("Failed to refresh token");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  const handleUserLogin = async () => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");

    if (!token) {
      navigate("/login");
      return;
    }

    if (isTokenValid(token)) {
      // Token is valid, fetch user info
      const userInfo = await fetchUserInfo(token);
      if (userInfo) {
        const { currentUser, role } = userInfo;
        dispatch(actUserLogin(currentUser, token, role, refreshToken));
      } else {
        // Token is invalid or expired, attempt to refresh it
        const refreshedTokens = await refreshTokenFu(refreshToken);
        if (refreshedTokens) {
          const { newAccessToken, newRefreshToken } = refreshedTokens;
          const retryUserInfo = await fetchUserInfo(newAccessToken);
          if (retryUserInfo) {
            const { currentUser, role } = retryUserInfo;
            dispatch(actUserLogin(currentUser, newAccessToken, role, newRefreshToken));
          } else {
            navigate("/login");
          }
        } else {
          navigate("/login");
        }
      }
    } else {
      // Access token is invalid or expired, check refresh token
      const refreshedTokens = await refreshTokenFu(refreshToken);
      if (refreshedTokens) {
        const { newAccessToken, newRefreshToken } = refreshedTokens;
        const retryUserInfo = await fetchUserInfo(newAccessToken);
        if (retryUserInfo) {
          const { currentUser, role } = retryUserInfo;
          dispatch(actUserLogin(currentUser, newAccessToken, role, newRefreshToken));
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, [navigate, dispatch]);

  useEffect(() => {
    handleUserLogin();
  }, [navigate, dispatch]);

  return null; // No need to render anything in this component
}
console.log('111');
console.log("222");


function App() {
  return (
    <>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
          <RouterContent />
        </Suspense>
      </HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
