import { API } from "./api";

export const appointmentService = {
  GetAppointmentByStatus(status, page, size) {
    return API.get(`appointments/GetAppointmentByStatus/${status}`, {
      params: {
        page,
        size,
      },
    });
  },
};
