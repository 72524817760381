import {
  ACT_ALL_PAYMENT,
  ACT_PAYMENT_BY_SALON_ID,
  ACT_ALL_PAYMENTP_HISTORY,
  ACT_ALL_PAYMENTP_REPORT,
  ACT_ALL_PAYMENTP_REPORT_ID,
} from "./action";

const initialState = {
  allPayments: [],
  paymentBySalonId: [],
  paymentHistory: [],
  paymentReport: [],
  paymentReportById: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACT_ALL_PAYMENT:
      return {
        ...state,
        allPayments: action.payload,
      };
    case ACT_PAYMENT_BY_SALON_ID:
      return {
        ...state,
        paymentBySalonId: action.payload,
      };
    case ACT_ALL_PAYMENTP_HISTORY:
      return {
        ...state,
        paymentHistory: action.payload,
      };
    case ACT_ALL_PAYMENTP_REPORT:
      return {
        ...state,
        paymentReport: action.payload,
      };
    case ACT_ALL_PAYMENTP_REPORT_ID:
      return {
        ...state,
        paymentReportById: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
